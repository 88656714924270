import { createClient } from "microcms-js-sdk";
import type { areas } from "../types/areas";
import type { shops } from "../types/shops";

export type shop = {
  area: {
    area: string;
    createdAt: string;
    id: string;
    publishedAt: string;
    revisedAt: string;
    updatedAt: string;
  };
  name: string;
  station: string;
  url: string;
  createdAt: string;
  id: string;
  publishedAt: string;
  revisedAt: string;
  updatedAt: string;
};

export type returnType = {
  areaList: Array<string>;
  areaShopList: Array<Array<shop>>;
};

const GetList = async (): Promise<returnType> => {
  const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;
  const apiKey = process.env.REACT_APP_API_KEY;
  const client = createClient({
    serviceDomain,
    apiKey,
  });

  const resultAreas: areas = await client.get({ endpoint: "area" });
  const areaList = resultAreas.contents.map((area) => area.area);

  const resultShops: shops = await client.get({
    endpoint: "shops",
    queries: { limit: 40 },
  });
  const areaShopList = areaList.map((area) =>
    resultShops.contents.filter((shop) => shop.area.area === area)
  );

  return {
    areaList,
    areaShopList,
  };
};

export default GetList;
