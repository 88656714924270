import List from "@mui/material/List";
//import { createClient } from "microcms-js-sdk";
import React, { useEffect, useState } from "react";
import GetList, { shop } from "./GetList";
//import type { areas } from "../types/areas";
//import type { shops } from "../types/shops";
import Shop from "./Shop";

/*
type shop = {
  area: {
    area: string;
    createdAt: string;
    id: string;
    publishedAt: string;
    revisedAt: string;
    updatedAt: string;
  };
  name: string;
  station: string;
  url: string;
  createdAt: string;
  id: string;
  publishedAt: string;
  revisedAt: string;
  updatedAt: string;
};
*/

const ShopList = () => {
  const [areaList, setAreaList] = useState<Array<string>>();
  const [areaShopList, setAreaShopList] = useState<Array<Array<shop>>>();
  //const serviceDomain = process.env.REACT_APP_SERVICE_DOMAIN;
  //const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    (async () => {
      const list = await GetList();
      setAreaList(list.areaList);
      setAreaShopList(list.areaShopList);
    })();

    /*
    (async () => {
      const client = createClient({
        serviceDomain,
        apiKey,
      });
      const resultAreas: areas = await client.get({ endpoint: "area" });
      const areas = resultAreas.contents.map((area) => area.area);
      setAreaList(areas);

      const resultShops: shops = await client.get({
        endpoint: "shops",
        queries: { limit: 20 },
      });
      const shops = areas.map((area) =>
        resultShops.contents.filter((shop) => shop.area.area === area)
      );
      setAreaShopList(shops);
    })();
    */
  }, []);
  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          position: "relative",
          overflow: "auto",
          maxHeight: "100vh",
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {areaShopList && areaList
          ? areaShopList.map((shops, index) => (
              <Shop shops={shops} area={areaList[index]} key={index} />
            ))
          : "...loading"}
      </List>
    </>
  );
};

export default ShopList;
