import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import React from "react";
import { shop } from "./GetList";

/*
type shop = {
  area: {
    area: string;
    createdAt: string;
    id: string;
    publishedAt: string;
    revisedAt: string;
    updatedAt: string;
  };
  name: string;
  station: string;
  url: string;
  createdAt: string;
  id: string;
  publishedAt: string;
  revisedAt: string;
  updatedAt: string;
};
*/

type Props = {
  shops: Array<shop>;
  area: string;
};

const Shop = (props: Props) => {
  //const shops = props.shops;
  //const area = props.area;
  const { shops, area } = props;
  return (
    <>
      <ListSubheader data-testid="subheader" role="list">
        {area}
      </ListSubheader>
      {shops.map((shop) => (
        <ListItemButton
          component="a"
          href={shop.url}
          key={shop.id}
          data-testid={shop.id}
        >
          <ListItemText primary={`${shop.station} - ${shop.name}`} />
        </ListItemButton>
      ))}
    </>
  );
};

export default Shop;
