import Container from "@mui/material/Container";
import React from "react";
import ShopList from "./components/ShopList";
function App() {
  return (
    <Container maxWidth="sm">
      <ShopList />
    </Container>
  );
}

export default App;
